import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from "@/shared/classes/form/form-base";
import baseHttp from "@/shared/http";
import IResponse from "@/shared/interfaces/modules/response.interface";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import _ from "lodash";
import Field from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import ObjectField from "@/shared/classes/form/fields/object-field";
import { LOCALES } from "@/shared/constants/locales.constants";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import __ from "@/shared/helpers/__";


export const setDefaultFieldValue = (field: Field | ObjectField | any, entry: any = null, form: FormBase | null = null, initialValues?: any) => {
  let key = field.entryKey ? field.entryKey : field.key
  let value: any = ''
  const initValues = initialValues ? initialValues : form?.initialValues
  if (form) value = _.get(initValues, key, null)
  if (entry) value = _.get(entry, key, null)

  switch (field.type) {
    case FieldTypes.object:
      value = {}
      field.children.forEach((child: Field) => {
        value[child.key] = setDefaultFieldValue(child, _.get(entry, key), form, _.get(initValues, key))
      })

      break
    case FieldTypes.datePicker:
    case FieldTypes.file:
    case FieldTypes.multipleFile:
    case FieldTypes.searchable:
      break
    case FieldTypes.checkbox:
    case FieldTypes.button:
      value = value ? value : false
      break
    case FieldTypes.multipleSearchable:
    case FieldTypes.dropzone:
      value = value ? value : []
      break
    case FieldTypes.array:
      value = (value ? value : new Array(field.initialLength).fill(null)).map((item: any, index: number) => {
        const modified: any = {}
        field.children.map((child: Field) => {
          _.set(modified, child.key, setDefaultFieldValue(child, item, form, _.get(initValues, `${ key }.${ index }`)))
        })

        return modified
      })
      break
    default:
      if (value === null) value = ''
      break
  }

  if (field.translatable) {
    let translatableValue: any = {}
    Object.keys(LOCALES).forEach((localeKey: string) => {
      translatableValue[localeKey] = _.get(value, localeKey, field.type === FieldTypes.file ? null : '')
    })
    value = translatableValue
  }


  return value
}

@Component
export default class AbstractForm extends Vue {
  @Prop() form!: FormBase
  http = baseHttp
  loadedEntry: boolean = false

  created() {
    this.presetInitialValues()
  }

  async submit() {
    this.form.setLoading(true)

    // Validate
    if (this.form.validate && !this.form.validate(this.form.generateDataForValidation())) {
      if (this.form.showDefaultValidationError) {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.error,
          message: __("validation.fill-all-field"),
        });
      }
      return this.form.setLoading(false)
    }

    if (this.form.beforeSubmit) await this.form.beforeSubmit()

    let { uuid, endpoint, model, onSuccess, initialCall, appendUuidOnSubmit } = this.form
    let data = this.generatePostData()
    data = this.form.changeDataBeforeSubmit ? this.form.changeDataBeforeSubmit(data) : data
    const call: any = this.http[this.form.method]

    if ((!endpoint || !call) && onSuccess) {
      this.form.setLoading(false)
      return onSuccess(data)
    }

    const callByEndpoint = initialCall ? endpoint : uuid ? `${ endpoint }${ appendUuidOnSubmit ? `/${ uuid }` : '' }` : endpoint
    await call(callByEndpoint, data, this.defaultParams())
      .then(async (response: IResponse<IModelResponse>) => {
        // const entry = model && !_.isEmpty(response.data) ? new model(response.data) : null
        const entry = model && !_.isEmpty(response.data) ? response.data?.length ? new model(response.data[0]) : new model(response.data) : null
        this.form.setErrors({})
        await onSuccess && onSuccess(response.data, entry, response)
        return response
      })
      .catch((error: any) => this.form.catchErrors(error))
      .finally(() => this.form.setLoading(false))
  }

  reset(): void {
    this.presetInitialValues()
  }

  private presetInitialValues() {
    if (this.form.entry) {
      this.parseValues(this.form.entry)
    } else if (this.form.uuid) {
      this.loadEntryAndPresetValues()
    } else {
      this.presetValues()
    }
  }

  private generatePostData(forValidation = false) {
    let data = _.cloneDeep(this.form.data)

    const recursive = (key: string, found?: Field | any, parentIndex: number | null = null) => {
      if (!found) found = this.form.fields.find((field: Field) => field.key === key)
      if (
        (!found && this.form.unsetNonFieldsValues) || (found && !found.visibleIf(data, parentIndex) && this.form.unsetNonFieldsValues)
      ) _.unset(data, key)

      if (found?.type === FieldTypes.array) {
        _.get(data, key).forEach((value: any, index: number) => {
          Object.keys(value).forEach((childKey: string) => {
            const childFound = found.children.find((field: Field) => field.key === childKey)
            recursive(`${ key }.${ index }.${ childKey }`, childFound, index)
          })
        })
      }

      if (found?.type === FieldTypes.object) {
        if (!_.get(data, key, null)) return
        Object.keys(_.get(data, key)).forEach((childKey: string) => {
          const childFound = found.children.find((field: Field) => field.key === childKey)
          recursive(`${ key }.${ childKey }`, childFound)
        })
      }
    }

    Object.keys(data).forEach((key: string) => recursive(key))

    data = this.injectValuesToData(data)

    return (this.form.files && !forValidation) ? this.formDataForFileFormat(data) : data
  }

  private formDataForFileFormat(data: any) {
    const { uuid, fields } = this.form
    const formData = new FormData()

    formData.append('_method', uuid ? 'PUT' : 'POST')

    Object.keys(data).forEach((key: string) => {
      const found = fields.find((field: Field) => field.key === key)
      let value = data[key]

      if (found && found.type === FieldTypes.checkbox) {
        formData.append(key, value ? '1' : '0')
        return
      }

      if (found && found.type === FieldTypes.file && found.translatable) {
        Object.keys(value).forEach((localeKey: string) => {
          const translatableValue = _.get(value, localeKey, null)
          if (translatableValue) formData.append(`${ key }_${ localeKey }`, translatableValue)
        })
        return
      }

      if (found && found.type === FieldTypes.file && (value instanceof Object) && !(value instanceof File)) {
        formData.append(key, '')
        return
      }

      if (found && (found.type === FieldTypes.multipleFile || found.type === FieldTypes.dropzone) && (value instanceof Array)) {
        value.forEach((file: File, index: number) => {
          formData.append(`${ key }_${ index }`, file)
        })

        return
      }

      if (found && found.type === FieldTypes.file && !value) return
      if (!value || typeof value === 'undefined') {
        formData.append(key, '')
        return
      }

      if (this.form.files && found && found.type === FieldTypes.object) {
        value = _.cloneDeep(data[key])
        Object.keys(value).forEach((childKey: string) => {
          const foundChild = (found as ObjectField).children.find((item: Field) => item.key === childKey)
          if (foundChild && foundChild.type === FieldTypes.multipleFile) {
            if (!value[childKey]) {
              delete value[childKey]
              return
            }
            value[childKey].forEach((file: File, index: number) => {
              formData.append(`${ key }_${ childKey }_${ index }`, file)
            })
            delete value[childKey]
          }
        })
        formData.append(key, JSON.stringify(value))
        return
      }

      if (found && found.translatable) {
        formData.append(key, JSON.stringify(value))
        return
      }

      formData.append(key, value)
    })

    return formData
  }

  private presetValues() {
    const data = this.getDefaultFieldValues()
    this.form.setData(data)
  }

  private getDefaultFieldValues() {
    const data: any = {}
    this.form.fields.forEach((field: Field) => {
      const value = setDefaultFieldValue(field, null, this.form)
      _.set(data, field.key, value)
    })

    return data
  }

  private loadEntryAndPresetValues() {
    const { endpoint, uuid, model, initialCall } = this.form
    const callByEndpoint = initialCall ? endpoint : `${ endpoint }/${ uuid }`

    this.http.get(callByEndpoint, this.defaultParams(true))
      .then((response: IResponse<IModelResponse>) => {
        const entry = new model(response.data)
        this.form.setEntry(entry)
        if (this.form.updateInjectValues) {
          this.form.setInjectValues({
            ...this.form.injectValues,
            ...this.form.updateInjectValues(entry),
          })
        }
        this.parseValues(entry)
        if (this.form.onEntryLoaded) this.form.onEntryLoaded(entry)
        this.loadedEntry = true
      })
  }

  private defaultParams(isGet: boolean = false) {
    let params: any = {}

    if (isGet && this.form.translatable) {
      params.hasTranslations = true
    }

    if (this.form.responseType) {
      params.responseType = this.form.responseType
    }

    if (isGet && this.form.appendedEndpointString) {
      const parts = this.form.appendedEndpointString.split('=')

      if (parts.length === 2) {
        params[parts[0]] = parts[1]
      }
    }

    return {
      params,
      headers: {
        ['Content-Type']: this.form.files ? 'multipart/form-data' : 'application/json',
      },
      ...(this.form.responseType ? { responseType: this.form.responseType } : {}),
    } as any
  }

  private parseValues(entry: any): void {
    const data: any = this.getDefaultFieldValues()

    this.form.fields.forEach((field: Field) => {
      let key = field.entryKey ? field.entryKey : field.key
      if (!entry.hasOwnProperty(key)) return
      if (field.type === FieldTypes.object || field.type === FieldTypes.array) {
        if (!entry[key] || entry[key].length === 0) {
          data[field.key] = setDefaultFieldValue(field, null, this.form)
          return
        }
        data[field.key] = setDefaultFieldValue(field, entry, this.form)
        return
      }

      const value = entry[key];
      if (field.translatable && typeof value == 'object' && Object.keys(value || {}).length === 0) return;

      if (field.translatable && typeof value == 'object') {
        data[field.key] = { ...(data[field.key] || {}), ...(value || {}) }
      }
      else {
        data[field.key] = value
      }

    })

    this.form.setData(data)
    this.loadedEntry = true
  }

  private injectValuesToData(data: any): void {
    const recursive = (fullKey: string) => {
      const value: any = _.get(this.form.injectValues, fullKey)
      if (value instanceof Object) {
        Object.keys(value).forEach((subKey: string) => recursive(`${ fullKey }.${ subKey }`))
        return
      }

      if (_.get(data, fullKey, null) === null || _.get(data, fullKey, null) === '') _.set(data, fullKey, value)
    }

    if (this.form.injectValues) {
      Object.keys(this.form.injectValues).forEach(recursive)
    }

    return data
  }
}
