


































































import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import Field from '@/shared/classes/form/field'
import __ from '@/shared/helpers/__'
import { FieldTypes } from '@/shared/components/form/field-types/index'
import { setDefaultFieldValue } from "@/shared/classes/form/abstract-form";
import { IFieldError } from "@/shared/interfaces/classes/form.interfaces";

@Component({
  components: {
    FormField: () => import('@/shared/components/form/FormField.vue')
  },
  methods: { __ }
})
export default class ArrayField extends AbstractField {
  addItem(): void {
    const value: any = {}

    this.field.children.forEach((field: Field) => {
      _.set(value, field.key, field.value ? field.value : setDefaultFieldValue(field))
    })

    _.set(this.form.data, this.fullKey, [
      ..._.get(this.form.data, this.fullKey),
      value
    ])

    this.removeError()
  }

  removeItem(index: number) {
    _.set(this.form.data, this.fullKey, _.get(this.form.data, this.fullKey).filter((value: any, i: number) => i !== index))
  }

  getChild(child: Field): Field {
    if (child.type === FieldTypes.checkbox) {
      return child
    }

    if (this.field.dense) {
      child.setDense(true)
    }

    return child
  }

  get hasError() {
    const error = this.getError();
    return !!(error && (error as IFieldError)?.messages?.length);
  }

  allowedToDelete(index: number) {
    if (this.field.disabled) return false;
    if (this.field.nonDeletableAmount > 0) return index >= this.field.nonDeletableAmount;

    return (this.field.firstDelete || ((!this.field.firstDelete) && this.value.length > 1))
  }
}
